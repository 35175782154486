import React from "react";
import { HttpError, IResourceComponentsProps, useDelete, useOne, useShow } from "@refinedev/core";

import {
    Show,
    DateField,
    useTable,
    useSimpleList,
    CreateButton,
    List,
    useDrawerForm,
    EditButton,
} from "@refinedev/antd";

import { Typography, Col, Row, Divider, List as AntdList, Avatar, Card, Table, Space, Dropdown, Menu } from "antd";
import { CreateWorkComment, CreateWorkItem, EditWorkItem, EditWork, WorkStatus, WorkType } from "../../components/works";
import { IWork, IWorkStatus, IUser, IWorkComment, IWorkItem } from "../../interfaces";
import {
    EnvironmentOutlined,
    PhoneOutlined,
    CalendarOutlined,
    UserOutlined,
    ClockCircleOutlined,
    FilePdfOutlined,
    MoreOutlined,
    EditOutlined,
    CloseCircleOutlined
} from "@ant-design/icons";
import { Comment } from "@ant-design/compatible";
import moment from "moment";

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

const { Title, Text } = Typography;

export const WorkShow: React.FC<IResourceComponentsProps> = () => {
    const { queryResult } = useShow<IWork>();
    const { data, isLoading } = queryResult;

    const work = data?.data;

    const { data: statusData, isLoading: statusIsLoading } =
        useOne<IWorkStatus>({
            resource: "work_status",
            id: work?.status_id || "",
            queryOptions: {
                enabled: !!work,
            },
        });

    const { data: createdByData, isLoading: createdByIsLoading } =
        useOne<IUser>({
            resource: "users",
            id: work?.created_by || "",
            queryOptions: {
                enabled: !!work,
            },
        });

    const { data: assignedUserData, isLoading: assignedUserIsLoading, fetchStatus: assignedUserFetchStatus } =
        useOne<IUser>({
            resource: "users",
            id: work?.assigned_user || "",
            queryOptions: {
                enabled: !!work?.assigned_user,
            },
        });

    console.log(assignedUserIsLoading);

    const //`useSimpleList` does not accept all of Ant Design's `List` component props anymore. You can directly use `List` component instead.,
        { listProps } = useSimpleList<IWorkComment>({
            resource: "work_comments",
            meta: {
                select: "*, users(full_name)",
            },
            initialSorter: [
                {
                    field: "created_at",
                    order: "desc",
                },
            ],
            permanentFilter: [
                {
                    field: "work_id",
                    operator: "eq",
                    value: work?.id,
                },
            ],
            initialPageSize: 4,
            queryOptions: {
                enabled: work !== undefined,
            },
            syncWithLocation: false,
        });

    const { tableProps, tableQueryResult } = useTable<IWorkItem>({
        resource: "work_items",

        meta: {
            select: "*, users(full_name)",
        },

        syncWithLocation: false,

        pagination: {
            mode: "off"
        },

        filters: {
            permanent: [
                {
                    field: "work_id",
                    operator: "eq",
                    value: work?.id,
                },
            ]
        }
    });

    const {
        drawerProps: editDrawerProps,
        formProps: editFormProps,
        saveButtonProps: editSaveButtonProps,
        show: editShow,
    } = useDrawerForm<IWork>({
        action: "edit",
        resource: "works",
        redirect: false,
        autoResetForm: true,
    });

    const {
        drawerProps: createWorkCommentDrawerProps,
        formProps: createWorkCommentFormProps,
        saveButtonProps: createWorkCommentSaveButtonProps,
        show: createWorkCommentShow,
    } = useDrawerForm<IWorkComment>({
        action: "create",
        resource: "work_comments",
        redirect: false,
        autoResetForm: true,
    });

    const {
        drawerProps: createWorkItemDrawerProps,
        formProps: createWorkItemFormProps,
        saveButtonProps: createWorkItemSaveButtonProps,
        show: createWorkItemShow,
    } = useDrawerForm<IWorkItem>({
        action: "create",
        resource: "work_items",
        redirect: false,
        autoResetForm: true,
    });

    const {
        drawerProps: editWorkItemDrawerProps,
        formProps: editWorkItemFormProps,
        saveButtonProps: editWorkItemSaveButtonProps,
        show: editWorkItemShow,
    } = useDrawerForm<IWorkItem>({
        action: "edit",
        resource: "work_items",
        redirect: false,
        autoResetForm: true,
    });

    const { mutate: mutateDelete } = useDelete();

    const moreMenuWorkItems = (id: number) => (
        <Menu
            mode="vertical"
            onClick={({ domEvent }) => domEvent.stopPropagation()}
        >
            <Menu.Item
                key="accept"
                style={{
                    fontSize: 15,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 500,
                }}
                icon={
                    <EditOutlined
                        style={{
                            color: "#52c41a",
                            fontSize: 17,
                            fontWeight: 500,
                        }}
                    />
                }
                onClick={() => {
                    editWorkItemShow(id)
                }}
            >
                Edit
            </Menu.Item>
            <Menu.Item
                key="reject"
                style={{
                    fontSize: 15,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 500,
                }}
                icon={
                    <CloseCircleOutlined
                        style={{
                            color: "#EE2A1E",
                            fontSize: 17,
                        }}
                    />
                }
                onClick={() => {
                    mutateDelete({
                        resource: "work_items",
                        id,
                        mutationMode: "undoable",
                    });
                }}
            >
                Delete
            </Menu.Item>
        </Menu>
    );


    dayjs.extend(utc)
    const originalTimezone = work?.timestamp?.slice(-6) ?? '';
    const offsetedTimestamp = dayjs(work?.timestamp).utcOffset(originalTimezone); //disable converting to local timezone in dayjs 


    return (
        <>
            <Show isLoading={isLoading} headerButtons={({ defaultButtons }) => (
                <>
                    {defaultButtons}
                    <EditButton onClick={() => editShow(work?.id)}></EditButton>
                </>
            )}>

                <Row justify="start" align="middle">
                    <Space size={"large"}>
                        <Col><Title style={{ marginBottom: 0 }} level={4} >{work?.description}</Title></Col>
                        {/* <Col flex={"20px"}></Col> */}
                        <Col><WorkType type={work?.work_type!} /></Col>
                        <Col>{statusIsLoading ? "Loading..." : <WorkStatus status={statusData?.data.status_name!}></WorkStatus>}</Col>
                    </Space>
                </Row>
                <Row justify="start" align="middle" style={{ marginTop: 10 }}>
                    <Col >
                        <Text type="secondary">Added by {createdByIsLoading ? "Loading..." : createdByData?.data.full_name} on <DateField type="secondary" format="D MMM YYYY HH:mm" value={offsetedTimestamp} /></Text>
                    </Col>
                </Row>

                <Divider orientation="left"></Divider>
                <Row justify="start" style={{ marginBottom: 10 }}><Title level={5} >Customer</Title></Row>
                <Row justify="start">
                    <Col >
                        <Text>
                            <UserOutlined /> {work?.cust_name}
                        </Text>
                    </Col>
                    <Col span={1}></Col>
                    <Col >
                        <Text>
                            <PhoneOutlined /> {work?.mobile}
                        </Text>
                    </Col>
                    <Col span={1}></Col>
                    <Col >
                        <Text>
                            <EnvironmentOutlined /> {work?.location}
                        </Text>
                    </Col>
                    <Col span={1}></Col>
                    <Col >
                        {work?.invoice_pdf ? (
                            work?.invoice_pdf.map((pdf) => (
                                <Text>
                                    <a href={pdf.url} target="_blank" rel="noopener noreferrer"><FilePdfOutlined /> Invoice / Quotation</a>
                                </Text>
                            ))
                        ) : (
                            null
                        )}
                    </Col>
                    <Col span={1}></Col>
                    <Col >
                        {work?.job_completion_pdf ? (
                            work?.job_completion_pdf.map((pdf) => (
                                <Text>
                                    <a href={pdf.url} target="_blank" rel="noopener noreferrer"><FilePdfOutlined /> Job Completion Form</a>
                                </Text>
                            ))
                        ) : (
                            null
                        )}
                    </Col>
                </Row>

                <Divider orientation="left"></Divider>
                <Row justify="start" style={{ marginBottom: 10 }}><Title level={5} >Assigned To</Title></Row>
                <Row justify="start">
                    <Col >
                        <Text>
                            <UserOutlined /> {assignedUserIsLoading && assignedUserFetchStatus !== 'idle' ? "Loading..." : assignedUserData?.data?.full_name}
                        </Text>
                    </Col>
                    <Col span={1}></Col>
                    <Col >
                        <Text>
                            <CalendarOutlined /> {work?.start_date ? <DateField format="D MMM YYYY" value={work?.start_date} /> : ""}
                        </Text>
                    </Col>
                    <Col span={1}></Col>
                    <Col >
                        <Text>
                            <ClockCircleOutlined /> {work?.start_time?.slice(0, -3)}
                        </Text>
                    </Col>
                </Row>
                <EditWork
                    drawerProps={editDrawerProps}
                    formProps={editFormProps}
                    saveButtonProps={editSaveButtonProps}
                />
            </Show>
            <Card style={{ marginTop: "2em" }}>
                <AntdList {...listProps} className="comment-list" itemLayout='horizontal'
                    header={<Row>
                        <Col><Title level={4}>Comments</Title></Col>
                        <Col flex="auto"></Col>
                        <Col ><CreateButton onClick={() => { createWorkCommentFormProps.form?.setFieldsValue({ work_id: work?.id }); createWorkCommentShow() }}></CreateButton></Col>
                    </Row>}
                    renderItem={renderWorkComment}
                />
                <CreateWorkComment
                    drawerProps={createWorkCommentDrawerProps}
                    formProps={createWorkCommentFormProps}
                    saveButtonProps={createWorkCommentSaveButtonProps}
                />
            </Card>
            <Card style={{ marginTop: "2em" }}>
                <List
                    breadcrumb={null}
                    title="Items"
                    headerProps={{
                        extra:
                            <Row>
                                <Col flex="auto"></Col>
                                <Col ><CreateButton onClick={() => { createWorkItemFormProps.form?.setFieldsValue({ work_id: work?.id }); createWorkItemShow() }}></CreateButton></Col>
                            </Row>
                    }}>
                    <Table {...tableProps} rowKey="id">
                        <Table.Column dataIndex="code" title="Code" />
                        <Table.Column dataIndex="name" title="Name" />
                        <Table.Column dataIndex={["users", "full_name"]} title="Added By" />
                        <Table.Column<IWorkItem>
                            width={80}
                            fixed="right"
                            title="Actions"
                            dataIndex="actions"
                            key="actions"
                            render={(_, record) => (
                                <Dropdown
                                    overlay={moreMenuWorkItems(record.id)}
                                    trigger={["click"]}
                                >
                                    <MoreOutlined
                                        onClick={(e) => e.stopPropagation()}
                                        style={{
                                            fontSize: 24,
                                        }}
                                    />
                                </Dropdown>
                            )}
                        />
                    </Table>
                    <CreateWorkItem
                        drawerProps={createWorkItemDrawerProps}
                        formProps={createWorkItemFormProps}
                        saveButtonProps={createWorkItemSaveButtonProps}
                    />
                    <EditWorkItem
                        drawerProps={editWorkItemDrawerProps}
                        formProps={editWorkItemFormProps}
                        saveButtonProps={editWorkItemSaveButtonProps}
                    />
                </List>
            </Card>
        </>
    );
};

const renderWorkComment = (item: IWorkComment) => {
    const { text } = item;

    return (
        <li>
            <Comment
                author={item.users.full_name}
                avatar={<Avatar src={item.users.avatar_url} />}
                content={<p>{text}</p>}
                datetime={moment.utc(item.created_at).format('dddd, D MMM YYYY HH:mm')}
            />

        </li>
    );
};
